<template>
<div>
  <list-page
      :columns="columns"
      :headers="headers"
      sort="id"
      :descending="true"
      title="Task Scheduler List"
      icon="mdi-folder-network"
      :show-add-button="false"
      stateendpoint="taskScheduler.taskSchedulers"
      :show-default-action-buttons="false"
      :actions="actions"
      :action-buttons="actionButton"
  />
</div>
</template>

<script>
import ListPage from "../components/ListPage";
import UserMixin from "../components/mixins/UserMixin";
import User from "../services/User";
export default {
  name: "TaskScheduler",
  mixins: [UserMixin],
  metaInfo: {
    title: 'rabbiitfirm.com',
    titleTemplate: 'Admin Dashboard - Task Scheduler | %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  components: {
    ListPage
  },
  data() {
    return {
      headers: [
        {
          text: 'Mailer',
          value: 'mailer'
        },
        {
          text: 'From ID',
          value: 'fromId'
        },
        {
          text: 'To ID',
          value: 'toId'
        },
        {
          text: 'Template',
          value: 'template'
        },
        {
          text: 'Type',
          value: 'type'
        },
        {
          text: 'Job Date',
          value: 'jobDate'
        },
        {
          text: 'Job Time',
          value: 'jobTime'
        },
        {
          text: 'Job Status',
          value: 'jobStatus'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: '', value: 'actions', sortable: false
        }
      ],
      columns: [
        {value: 'email'},
        {value: 'isActive'},
        {value: 'action'},
      ],
      actions: {
        load: 'loadTaskSchedulers',
        update: 'updateTaskScheduler',
        remove: 'removeTaskScheduler',
        destroy: 'destroyTaskScheduler'
      },
      actionButton: [
        {
          category: 'delete',
          icon: 'mdi-delete',
          color: 'error',
          text: 'Delete',
          clickHandler: 'deleteItem'
        },
        {
          category: 'deletePermanent',
          icon: 'mdi-delete-forever',
          color: 'error',
          text: 'Delete Forever',
          clickHandler: 'destroyItem'
        }
      ]
    }
  },
  mounted() {
    User.auth()
    .then(({data})=> {
      if(data.role_id !== 1) {
        this.$router.push({path: '/'});
      }
    })
    .catch(error=> {
      this.$router.push({path: '/'});
    })
  }
}
</script>

<style scoped>

</style>